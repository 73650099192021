<template>
	<div>
		<div v-for="(item, itemI) in signRoutes" :key="itemI">
			<div class="dF fC aC" :class="item.length > 1 ? 'grouped-signers' : ''">
				<div class="dF aC">
					<a-tooltip overlayClassName="change-tooltip-color" v-for="(s, sI) in item.signers" :key="itemI + 'signer' + sI">
						<template slot="title">
							<div>{{ s.name }}</div>
							<small class="block">{{ s.supportingText }}</small>
							<em>{{ s.action === 'sign' ? 'Signer' : s.action === 'cc' ? 'Receives Copy' : s.action ===
								'approve' ? 'Approver' : 'Don\'t Send Copy' }}</em>
						</template>
						<div class="relative dF jC">
							<a-avatar :size="64" class="mx-2 my-2">{{ s.name.split(' ').map(x => x[0]).join('') }}
							</a-avatar>
							<a-icon class="signer-action-icon"
								:type="s.action === 'sign' ? 'edit' : s.action === 'cc' ? 'mail' : s.action === 'approve' ? 'eye' : 'stop'" />
							{{ s.ant }}
						</div>
					</a-tooltip>
				</div>
				<i class="fe fe-arrow-down" v-if="signRoutes[itemI + 1]" />
			</div>
		</div>
	</div>
</template>

<script>

export default {
	props: ['signRoutes']
}
</script>

<style>
</style>
